*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.main-title {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3vw;
    text-align: center;
    color: #ffffff;
    
    font-family: 'Oxygen', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3vw;
}

.main-title img {
    max-width: 100%;
}